<template>
  <div v-show="navigation">
    <v-btn
      elevation="1"
      fab
      small
      fixed
      top
      left
      v-show="false"
      class="destination-btn elevation-3 d-flex flex-row justify-space-between align-item-center"
      @click="openDestinationDialog()"
      color="#fff"
      :style="[
        this.$store.getters.place &&
        this.$store.getters.placeConfig.campaign &&
        this.$store.getters.campaign.length > 0
          ? { 'margin-left': '80px' }
          : {},
      ]"
    >
      <div class="search-icon">
        <v-icon size="25px"> mdi-magnify </v-icon>
      </div>
      <div>
        <h3 class="text--grey destination-title">
          {{
            $store.getters.searchDestination &&
            $store.getters.routeInfo.length === 0
              ? $store.getters.searchDestination
              : $store.getters.routeInfo[$store.getters.routeInfo.length - 1]
                  ?.title
              ? $store.getters.routeInfo[$store.getters.routeInfo.length - 1]
                  .title
              : $t("searchLabel")
          }}
        </h3>
      </div>

      <div class="searchdestination-icon" @click="handleClearSearchBar">
        <v-btn
          v-if="
            $store.getters.searchDestination ||
            $store.getters.routeInfo[$store.getters.routeInfo.length - 1]?.title
          "
          color="blue darken-1"
          size="sm"
          text
          elevation="0"
          width="20px"
        >
          <v-icon size="30px"> mdi-close </v-icon>
        </v-btn>
        <CategoryButton />
      </div>
    </v-btn>

    <div
      class="float-lg-left pt-6 pl-12 pr-12 ml-5 mr-5 transperant"
      style="max-width: 600px; position: relative; z-index: 9999"
      v-if="navigation"
    >
      <div v-if="$store.getters.destinationDialog === true">
        <v-dialog
          v-model="$store.getters.destinationDialog"
          :fullscreen="isMobileOnly ? true : false"
          :scrollable="false"
          max-width="600px"
          persistent
          no-click-animation
          @click.stop="$store.dispatch('destinationDialog', true)"
          style="z-index: 9999999; overflow-y: unset !important"
          hide-on-leave="true"
          transition="fade-transition"
          content-class="destination-dialog"
        >
          <v-card v-if="$store.getters.placesLoading === false">
            <v-card-title style="padding: 0">
              <div class="d-flex flex-column w-100 primary mb-5">
                <v-toolbar flat dark color="primary">
                  <v-btn
                    icon
                    dark
                    @click="$store.dispatch('destinationDialog', false)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-text-field
                  :label="$t('searchLabel')"
                  solo
                  class="px-4"
                  v-model="$store.getters.searchDestination"
                  @input="handleChangeSearchDestinationInput"
                  clearable
                  @click:clear="handleClearSearchDestination"
                  prepend-inner-icon="mdi-map-marker"
                  @focus="destinationTextFieldFocus"
                  ref="destinationInput"
                >
                </v-text-field>
              </div>
            </v-card-title>

            <v-card-text style="padding: 0">
              <VueSlickCarousel
                class="mb-5"
                :arrows="true"
                :dots="true"
                :infinite="false"
                :speed="500"
                :slidesToShow="slidesToShow"
                :slidesToScroll="slidesToScroll"
                :initialSlide="0"
                :prevArrow="prevArrow"
                :nextArrow="nextArrow"
                :responsive="responsiveSettings"
              >
                <div
                  v-for="(item, index) in $store.getters.place.data.facilities"
                  :key="index"
                  class="cursor-pointer"
                >
                  <div @click="searchFacilities(item.pointType, item.title)">
                    <div class="d-flex flex-column justify-content-center">
                      <div class="mb-5 d-flex flex-row justify-content-center">
                        <img
                          height="80"
                          width="80"
                          :src="item.icon"
                          :alt="item.title"
                          :style="{ 'object-fit': 'contain' }"
                        />
                      </div>
                      <div>
                        <h4 class="black--text">{{ item.title }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </VueSlickCarousel>

              <v-divider></v-divider>

              <div
                v-if="
                  $store.getters.searchDestination && filterStoreList.length > 0
                "
              >
                <v-container>
                  <v-btn
                    depressed
                    color="primary"
                    block
                    class="mt-5 mb-5"
                    @click="handleShowAllResult(!$store.getters.showClusters)"
                  >
                    <v-icon left dark> mdi-near-me </v-icon>
                    {{
                      !$store.getters.showClusters === true
                        ? $t("showAllResultButton")
                        : $t("hideAllResultButton")
                    }}
                  </v-btn>
                </v-container>
                <v-divider></v-divider>
              </div>
              <div
                v-if="filterStoreList.length <= 0"
                class="text-center pt-5 d-flex justify-center align-center"
                style="height: 400px"
              >
                <v-typography class="subtitle-1" align="center">
                  {{ $t("resultNotFound") }}
                </v-typography>
              </div>

              <div v-else>
                <v-virtual-scroll
                  bench="0"
                  :items="filterStoreList"
                  height="400"
                  :item-height="80"
                  class="mx-auto"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item
                      @click="clickOnListItem(item.id)"
                      :key="item.id"
                    >
                      <v-list-item-content>
                        <div class="d-flex flex-row">
                          <v-list-item-subtitle>
                            <div
                              class="d-flex flex-row align-item-center justify-content-start"
                            >
                              <h3 class="fw-bold text-black">
                                {{ item.title }}
                              </h3>
                            </div>
                          </v-list-item-subtitle>
                        </div>
                        <div
                          class="d-flex flex-row justify-content-center mt-3 align-item-center"
                        >
                          <v-list-item-subtitle>
                            <div
                              class="d-flex flex-row align-item-center justify-content-start"
                            >
                              <span>{{
                                renderFloorData(
                                  item.location.properties.floorLevel
                                )
                              }}</span>
                              {{ item.category ? "," : "" }}

                              <span class="ml-1">
                                {{
                                  item.category
                                    ? Object.prototype.hasOwnProperty.call(
                                        item.category,
                                        "subcategory"
                                      )
                                      ? item.category.subcategory.name
                                        ? item.category.subcategory.name
                                        : item.category.name
                                      : item.category.name
                                    : ""
                                }}
                              </span>
                              <!-- <span class="ml-1">{{ item.distance }}</span> -->
                            </div>
                          </v-list-item-subtitle>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="item.id + 1"></v-divider>
                  </template>
                </v-virtual-scroll>
              </div>
            </v-card-text>

            <SimpleKeyboard
              @onChange="handleChangeSearchDestinationInput"
              :input="$store.getters.searchDestination"
              @onToggleKeyboard="onToggleKeyboard"
              :showKeyboard="showKeyboard"
            />
          </v-card>
          <v-card v-else>
            <v-skeleton-loader
              class="mx-auto p-5"
              max-width="100%"
              type=" article, actions"
            ></v-skeleton-loader>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobileOnly, isTablet } from "mobile-device-detect";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { turkishToEnglish } from "../utilities/turkishTonEnglish";
import SimpleKeyboard from "./SimpleKeyboard";
import * as turf from "@turf/turf";
import { isZoneReachableFromZone } from "../utilities/mapUtilities";

// optional style for arrows & dots

import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { postAnalysesDataAsync } from "../services/services";
import CategoryButton from "@/components/CategoryButton.vue";

export default {
  name: "DestinationBox",
  components: {
    VueSlickCarousel,
    SimpleKeyboard,
    CategoryButton,
  },
  props: {
    map: Object,
  },
  data() {
    return {
      store: null,
      stores: [],
      storeList: [],

      isMobileOnly: isMobileOnly || isTablet ? true : false,
      showAllResults: false,
      destinationKeyword: null,
      debounce: null,
      showKeyboard: false,
    };
  },

  methods: {
    destinationTextFieldFocus() {
      // Show the keyboard
      this.showKeyboard = true;

      this.$nextTick(() => {
        const inputElement =
          this.$refs.destinationInput.$el.querySelector("input");

        if (inputElement) {
          const length = inputElement.value.length;

          inputElement.setSelectionRange(length, length);
          inputElement.focus(); // Ensure the input is focused
        }
      });
    },
    destinationTextFieldBlur() {
      this.showKeyboard = false;
    },
    renderFloorData(floorLevel) {
      let floorData = "";

      this.$store.getters.floors.forEach((item) => {
        if (item.level === floorLevel) {
          floorData = item.title;
        }
      });

      return floorData;
    },
    handleClearSearchDestination() {
      this.$store.dispatch("searchDestination", "");
      this.$store.dispatch("showClusters", false);
      this.$store.dispatch("destinationDialog", true);
    },
    handleClearSearchBar(e) {
      e.stopPropagation();

      if (
        this.$store.getters.searchDestination ||
        this.$store.getters.shopInfo.title ||
        this.$store.getters.routeInfo[this.$store.getters.routeInfo.length - 1]
          ?.title
      ) {
        this.$store.dispatch("searchDestination", "");
        this.$store.dispatch("showClusters", false);
        this.$store.dispatch("destinationDialog", false);
        this.$store.dispatch("reset");
        this.$emit("handleRemovePolygon");
      } else {
        this.$store.dispatch("destinationDialog", true);
      }
    },
    handleChangeSearchDestinationInput(e) {
      // Move the cursor to the end of the input text
      this.$nextTick(() => {
        const inputElement =
          this.$refs.destinationInput.$el.querySelector("input");

        if (inputElement) {
          const length = inputElement.value.length;

          inputElement.setSelectionRange(length, length);
          inputElement.focus(); // Ensure the input is focused
        }
      });
      if (e) {
        this.$store.dispatch("searchDestination", e);
        this.$store.dispatch("routeType", "search");
        clearTimeout(this.debounce);

        this.debounce = setTimeout(() => {
          this.destinationKeyword = e;
          this.$store.dispatch("postSearchData", {
            keyword: e,
            type: "SEARCH",
          });
        }, 3000);
      } else {
        this.$store.dispatch("routeType", "scroll");
        clearTimeout(this.debounce);
        this.$store.dispatch("searchDestination", e);

        this.$store.dispatch("showClusters", false);
      }
    },

    onToggleKeyboard(toggle) {
      this.showKeyboard = toggle;
    },
    searchFacilities(pointType, title) {
      this.$emit("handleSelectedFacilities", pointType, title);
    },
    hanldePaintPolygon(point) {
      this.$emit("handleClickOnMap", point);
    },

    async handleShowAllResult(toggle) {
      this.$store.dispatch("routeType", "showAllResults");
      this.$store.dispatch("reset");
      this.showAllResults = this.$store.getters.showClusters;
      this.$store.dispatch("clustersList", this.filterStoreList);
      this.$store.dispatch("showClusters", toggle);
      this.$store.dispatch("destinationDialog", false);

      if (toggle === true) {
        this.$store.dispatch("destinationDialog", false);
        await postAnalysesDataAsync({
          eventData: {
            timestamp: Math.floor(new Date().getTime() / 1000),

            keyword: this.$store.getters.searchDestination,
            pointIds: this.filterStoreList.map((item) => item.id),
          },
          eventKey: "SHOW_ALL_RESULTS",
        });
      }
    },

    //Search store by title and get information
    clickOnListItem(store) {
      let urlParams = new URLSearchParams(window.location.search);
      const startPoint = urlParams.get("startStoreId");
      const startNodeId = urlParams.get("nodeId");

      const hasStartPoint = urlParams.has("startStoreId");
      const hasStartNodeId = urlParams.has("nodeId");

      if (this.$store.getters.searchDestination) {
        this.$store.dispatch("routeType", "search");
      } else {
        this.$store.dispatch("routeType", "scroll");
      }

      let routeStartPoint = "";
      let routeFloor = "";
      let routeCenter = "";
      let routeEndPoint = "";

      this.$store.getters.stores.forEach((element) => {
        if (hasStartPoint === false && hasStartNodeId === false) {
          if (element.id === store) {
            let place = {
              title: element.title,
              Id: element.id,
              floor: element.location.properties.floorLevel,
              center: element.location.geometry.coordinates,
            };
            //get found store info
            this.$store.dispatch("getStoreLocation", place);
            const shopIcon = element.category ? element.category.icon : "";
            const tags = element.tags ? element.tags : [];
            const zoneId = element.properties
              ? element.properties.zoneId
                ? element.properties.zoneId
                : ""
              : "";

            this.$store.dispatch("shopInfo", {
              title: element.title,
              category: element.category,
              icon: shopIcon,
              properties: element.properties,
              tags: tags,
              zoneId: zoneId,
              isCluster: false,
            });

            if (this.$store.getters.mapRef) {
              this.hanldePaintPolygon({
                lng: element.location.properties.shopCenterPoint[0],
                lat: element.location.properties.shopCenterPoint[1],
              });
            }

            this.$store.dispatch("destinationDialog", false);
          }
        } else if (hasStartPoint === true) {
          if (element.filters.store_ids.includes(startPoint)) {
            routeStartPoint = element.id;
            routeFloor = element.location.properties.floorLevel;
            routeCenter = element.location.properties.centerPoint;
          }
          if (element.id === store) {
            routeEndPoint = element.id;
            const shopIcon = element.category ? element.category.icon : "";
            const tags = element.tags ? element.tags : [];
            const zoneId = element.properties
              ? element.properties.zoneId
                ? element.properties.zoneId
                : ""
              : "";

            if (this.$store.getters.mapRef) {
              this.hanldePaintPolygon({
                lng: element.location.properties.shopCenterPoint[0],
                lat: element.location.properties.shopCenterPoint[1],
              });
            }
            this.$store.dispatch("shopInfo", {
              title: element.title,
              category: element.category,
              icon: shopIcon,
              properties: element.properties,
              tags: tags,
              zoneId,
              isCluster: false,
            });
          }
        } else if (hasStartNodeId === true) {
          if (element.id === startNodeId) {
            routeStartPoint = element.id;
            routeFloor = element.location.properties.floorLevel;
            routeCenter = element.location.properties.centerPoint;
          }
          if (element.id === store) {
            routeEndPoint = element.id;
            const shopIcon = element.category ? element.category.icon : "";
            const tags = element.tags ? element.tags : [];
            const zoneId = element.properties
              ? element.properties.zoneId
                ? element.properties.zoneId
                : ""
              : "";

            this.$store.dispatch("shopInfo", {
              title: element.title,
              category: element.category,
              icon: shopIcon,
              properties: element.properties,
              tags: tags,
              zoneId,
              isCluster: false,
            });
          }
        }
      });

      // check of is start point is exist or not
      if (startPoint) {
        this.$store.dispatch("getRoute", {
          floor: routeFloor,
          center: routeCenter,
          startPoint: routeStartPoint,
          endPoint: routeEndPoint,
        });
        this.$store.dispatch("showClusters", false);
        this.$store.dispatch("destinationDialog", false);
        this.$store.dispatch("searchDestination", "");
      }

      if (startNodeId) {
        this.$store.dispatch("getRoute", {
          floor: routeFloor,
          center: routeCenter,
          startPoint: routeStartPoint,
          endPoint: routeEndPoint,
          routeType: "storeId",
        });
        this.$store.dispatch("showClusters", false);
        this.$store.dispatch("destinationDialog", false);
        this.$store.dispatch("searchDestination", "");
      }
    },
    clearSearch() {
      //Reset all state
      this.$store.dispatch("reset");
      this.$store.dispatch("showClusters", false);
    },

    wc() {
      //Get WC location
      this.$emit("dialog", "wc");
    },
    elevator() {
      //Get elevator location
      this.$emit("dialog", "elevator");
      //
    },

    openDestinationDialog() {
      if (
        this.$store.getters.placeConfig?.floorDropdown?.expandable === false ||
        this.$store.getters.placeConfig?.floorDropdown?.expandable === undefined
      ) {
        this.$store.dispatch("openFloorsDropDown", false);
      }

      this.$store.dispatch("destinationDialog", true);
    },
    sortArrayAlphabetically(arr) {
      let urlParams = new URLSearchParams(window.location.search);
      const startPoint = urlParams.get("startStoreId");
      const language = localStorage.getItem("language");

      // Use Intl.Collator for language-sensitive string comparison
      const collator = new Intl.Collator(language, { sensitivity: "base" }); // 'tr' is the language code for Turkish

      const sortedAlphabetically = arr.sort((a, b) => {
        return collator.compare(a.title, b.title);
      });

      // Sorting by distance remains the same
      const sortedByDistance = sortedAlphabetically.sort((a, b) => {
        return a.distance - b.distance;
      });

      if (startPoint) {
        return sortedByDistance;
      } else {
        return sortedAlphabetically;
      }
    },
    handleMultiStoreIds() {
      const multiStoreIds = this.$store.getters.urlParamsConfigs.multiStoreIds;
      if (!this.$store.getters.placesLoading) {
        if (multiStoreIds.length > 0) {
          let clusterFilterList = [];
          clusterFilterList = this.$store.getters.stores.filter((store) => {
            return multiStoreIds.find((id) => {
              if (store.filters.store_ids.includes(id.toString())) {
                return store;
              }
            });
          });

          this.$store.dispatch("clustersList", clusterFilterList);
          this.$store.dispatch("showClusters", true);
        }
      }
    },
    handleParamsRouting() {
      let urlParams = new URLSearchParams(window.location.search);
      const startPoint = urlParams.get("startStoreId");
      const endPoint = urlParams.get("endStoreId");

      const startNodeIdPoint = urlParams.get("nodeId");
      const endNodeIdPoint = urlParams.get("endNodeId");
      const hasStartNodeIdPoint = urlParams.has("nodeId");
      const hasEndNodeIdPoint = urlParams.has("endNodeId");
      const destinationDialogParam =
        this.$store.getters.urlParamsConfigs.destinationDialog !== ""
          ? this.$store.getters.urlParamsConfigs.destinationDialog === true
            ? true
            : false
          : true;

      if (this.$store.getters.placesLoading !== true) {
        if (
          urlParams.has("startStoreId") === true &&
          urlParams.has("endStoreId") === false
        ) {
          this.$store.dispatch("destinationDialog", destinationDialogParam);
        } else if (
          urlParams.has("endStoreId") === true &&
          urlParams.has("startStoreId") === false
        ) {
          this.$store.getters.stores.forEach((element) => {
            if (element.filters.store_ids.includes(endPoint)) {
              let place = {
                title: element.title,
                Id: element.id,
                floor: element.location.properties.floorLevel,
                center: element.location.geometry.coordinates,
              };

              //get found store info
              const shopIcon = element.category ? element.category.icon : "";
              const tags = element.tags ? element.tags : [];
              const zoneId = element.properties
                ? element.properties.zoneId
                  ? element.properties.zoneId
                  : ""
                : "";
              this.$store.dispatch("getStoreLocation", place);

              this.$store.dispatch("shopInfo", {
                title: element.title,
                category: element.category,
                icon: shopIcon,
                properties: element.properties,
                tags: tags,
                zoneId,
                isCluster: false,
                center: element.location.properties.shopCenterPoint,
              });

              this.$store.dispatch("showClusters", false);
              this.$store.dispatch("routeType", "storeId");
              this.$store.dispatch("destinationDialog", false);
            }
          });
        } else if (
          urlParams.has("startStoreId") === true &&
          urlParams.has("endStoreId") === true
        ) {
          this.$store.dispatch("reset");

          let routeFloor = "";
          let routeCenter = "";
          let routeStartPoint = "";
          let routeEndPoint = "";

          this.$store.getters.stores.forEach((element) => {
            if (element.filters.store_ids.includes(startPoint)) {
              routeFloor = element.location.properties.floorLevel;
              routeCenter = element.location.geometry.coordinates;
              routeStartPoint = element.id;
            }
            if (element.filters.store_ids.includes(endPoint)) {
              routeEndPoint = element.id;
            }
          });

          this.$store.dispatch("getRoute", {
            floor: routeFloor,
            center: routeCenter,
            startPoint: routeStartPoint,
            endPoint: routeEndPoint,
            routeType: "storeId",
          });
        } else if (
          hasStartNodeIdPoint === true &&
          hasEndNodeIdPoint === false
        ) {
          this.$store.dispatch("destinationDialog", destinationDialogParam);
        } else if (
          hasStartNodeIdPoint === false &&
          hasEndNodeIdPoint === true
        ) {
          this.$store.getters.stores.forEach((element) => {
            if (element.id === endNodeIdPoint) {
              let place = {
                title: element.title,
                Id: element.id,
                floor: element.location.properties.floorLevel,
                center: element.location.geometry.coordinates,
              };

              //get found store info
              const shopIcon = element.category ? element.category.icon : "";
              const tags = element.tags ? element.tags : [];
              const zoneId = element.properties
                ? element.properties.zoneId
                  ? element.properties.zoneId
                  : ""
                : "";
              this.$store.dispatch("getStoreLocation", place);

              this.$store.dispatch("shopInfo", {
                title: element.title,
                category: element.category,
                icon: shopIcon,
                properties: element.properties,
                tags: tags,
                zoneId,
                isCluster: false,
                center: element.location.properties.shopCenterPoint,
              });
              this.$store.dispatch("showClusters", false);
              this.$store.dispatch("destinationDialog", false);
            }
          });
        } else if (hasStartNodeIdPoint === true && hasEndNodeIdPoint === true) {
          this.$store.dispatch("reset");

          let routeFloor = "";
          let routeCenter = "";
          let routeStartPoint = "";
          let routeEndPoint = "";

          this.$store.getters.stores.forEach((element) => {
            if (element.id === startNodeIdPoint) {
              routeFloor = element.location.properties.floorLevel;
              routeCenter = element.location.geometry.coordinates;
              routeStartPoint = element.id;
            }
            if (element.id === endNodeIdPoint) {
              routeEndPoint = element.id;
            }
          });

          this.$store.dispatch("getRoute", {
            floor: routeFloor,
            center: routeCenter,
            startPoint: routeStartPoint,
            endPoint: routeEndPoint,
            routeType: "nodeId",
          });
        }
      }
    },
  },
  //when component is mounted get all store items and filter shop and visible ones
  async mounted() {
    if (!this.$store.getters.stores.length) {
      await this.$store.dispatch("getStores");

      this.handleParamsRouting();
      this.handleMultiStoreIds();
      this.$store.getters.stores.forEach((element) => {
        if (
          // element.filters.pointType[0] === "shop" &&
          element.navigation.properties.isVisibleOnList === true
        ) {
          this.stores.push(element);
        }
      });
    } else {
      this.$store.getters.stores.forEach((element) => {
        if (
          // element.filters.pointType[0] === "shop" &&
          element.navigation.properties.isVisibleOnList === true
        ) {
          this.stores.push(element);
        }
      });
    }
  },
  computed: {
    navigation() {
      const paramsNavigationConfig =
        this.$store.getters.urlParamsConfigs.navigation;
      const dataNavigationConfig = this.$store.getters.navigation;

      if (paramsNavigationConfig === false && dataNavigationConfig === true)
        return false;
      return dataNavigationConfig;
    },
    filterStoreList() {
      let urlParams = new URLSearchParams(window.location.search);
      const startPoint = urlParams.get("startStoreId");
      let startPointCoordinates = [];
      let startZoneId = "";

      const zoneList = this.$store.getters.place.data.properties.zonePassList;

      const paramsTopPoiCountConfig =
        this.$store.getters.urlParamsConfigs.topPoiCount;
      const dataTopPoiCountConfig = this.$store.getters.placeConfig.topPoiCount;
      let topPoiCount = paramsTopPoiCountConfig || dataTopPoiCountConfig;

      if (startPoint) {
        this.$store.getters.stores.map((item) => {
          if (item.filters.store_ids.includes(startPoint.toString()) === true) {
            startZoneId = item.properties.zoneId;
            startPointCoordinates = item.location.geometry.coordinates;
          }
        });
      }

      if (
        this.$store.getters.searchDestination ||
        topPoiCount === undefined ||
        topPoiCount === null
      ) {
        let distancArrays = [];

        return this.sortArrayAlphabetically(
          this.stores
            .filter((store) => {
              const tagArray = Object.prototype.hasOwnProperty.call(
                store,
                "tags"
              )
                ? store.tags
                : [];
              const subcategoryName = store.category?.subcategory
                ? turkishToEnglish(
                    store.category.subcategory.name.toLowerCase()
                  )
                  ? turkishToEnglish(
                      store.category.subcategory.name.toLowerCase()
                    )
                  : ""
                : "";
              let searchDestination = turkishToEnglish(
                this.$store.getters.searchDestination.toLowerCase()
              );
              let storeTitle = turkishToEnglish(store.title.toLowerCase());
              let storeCategory = turkishToEnglish(
                store.category?.name ? store.category.name.toLowerCase() : ""
              );

              return (
                storeTitle.includes(searchDestination) ||
                storeCategory.includes(searchDestination) ||
                tagArray.some((tag) => {
                  let tagConvertor = turkishToEnglish(tag.toLowerCase());
                  return tagConvertor.toLowerCase().includes(searchDestination);
                }) ||
                subcategoryName.toLowerCase().includes(searchDestination)
              );
            })
            .map((store) => {
              if (startPoint && startPointCoordinates.length > 0) {
                let startPointCoord = turf.point(startPointCoordinates);
                let endPointCoord = turf.point(
                  store.location.geometry.coordinates
                );
                let distanceInMiles;

                if (startPointCoordinates.length > 0) {
                  distanceInMiles = turf.distance(
                    startPointCoord,
                    endPointCoord,
                    {
                      units: "miles",
                    }
                  );
                }

                distancArrays.push({
                  title: store.title.toLowerCase(),
                  distance: distanceInMiles,
                });

                distancArrays.sort((a, b) => a.distance - b.distance);

                return { ...store, distance: distanceInMiles };
              } else {
                return { ...store, distance: null };
              }
            })
            .filter((store) => {
              if (startZoneId) {
                return (
                  isZoneReachableFromZone(
                    zoneList,
                    startZoneId,
                    store.properties.zoneId
                  ) === true
                );
              } else {
                return true;
              }
            })
        );
      } else {
        return this.stores.slice(0, topPoiCount);
      }
    },
    slidesToShow() {
      return this.$store.getters.place.data.facilities.length <= 2 ? 2 : 3;
    },
    slidesToScroll() {
      return this.$store.getters.place.data.facilities.length <= 2 ? 2 : 3;
    },
    prevArrow() {
      return '<button class="custom-prev"><i class="fa fa-arrow-left"></i></button>';
    },
    nextArrow() {
      return '<button class="custom-next"><i class="fa fa-arrow-right"></i></button>';
    },
    responsiveSettings() {
      const slides =
        this.$store.getters.place.data.facilities.length <= 2 ? 2 : 3;
      return [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: slides,
            slidesToScroll: slides,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: slides,
            slidesToScroll: slides,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.destination-btn {
  background-color: #fff;
  border-radius: 4px;
  padding: 25px 8px;
  width: fit-content;
  min-width: 300px;
  width: 37%;
}
.destination-btn::v-deep .v-btn__content {
  justify-content: center !important;
  display: flex;
}
.destination-btn .destination-title {
  color: rgb(55, 55, 55);
  font-size: 14px;
  text-transform: none;
  line-height: 10;
  font-weight: bold;
  text-align: center;
  display: block;
}

.destination-btn .destination-title::first-letter {
  text-transform: capitalize;
}

.searchdestination-icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.search-icon {
  position: absolute;
  left: 5px;
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
  .destination-btn {
    width: 50%;
  }
}
@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/
  .destination-btn {
    width: 90%;
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  .destination-btn {
    width: 90%;
  }
}
</style>

<style>
.destination-dialog {
  overflow: unset !important;
}
.close-keyboard {
  position: absolute;
  top: -30px;
  right: 20px;
  background-color: #ececec;
  z-index: 1;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.open-keyboard {
  position: absolute;
  bottom: 10px;
  right: 20px;
  background-color: #ececec;
  z-index: 1;
  border-radius: 4px;
  padding: 1px 7px;
}

.slick-prev {
  left: 0;
  background-color: transparent;
  border-radius: 50%;
  z-index: 99999;
  width: 30px;
  height: 30px;
}
.slick-next {
  right: 0;
  background-color: transparent;
  border-radius: 50%;
  z-index: 99999;
  width: 30px;
  height: 30px;
}

.slick-prev:hover,
.slick-prev:focus {
  background-color: transparent;
}
.slick-next:hover,
.slick-next:focus {
  background-color: transparent;
}
.slick-prev:before {
  position: relative;
  /* Uncomment the following line to adjust vertical alignment of the icon */
  /* top: 3pt; */
  content: "";
  display: inline-block;
  /* Use em scale for dynamic sizing based on font */
  width: 0.8em;
  height: 0.8em;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(-135deg); /* Rotate to create a left chevron */
  margin-left: 0.5em;
  border-radius: 1px;
}

.slick-next:before {
  position: relative;
  /* Uncomment the following line to adjust vertical alignment of the icon */
  /* top: 3pt; */
  content: "";
  display: inline-block;
  /* Use em scale for dynamic sizing based on font */
  width: 0.8em;
  height: 0.8em;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(45deg); /* Rotate to create a right chevron */
  margin-right: 0.5em;
  border-radius: 1px;
}
</style>
